export default {
	apiGeoserverWFSProtocol: 'https',
	apiGeoserverWFSHost: 'insar.gisat.cz',

	apiGeoserverWMSProtocol: 'https',
	apiGeoserverWMSHost: 'insar.gisat.cz',

	apiBackendProtocol: 'https',
	apiBackendHost: 'insar.gisat.cz',

	geoServerUrl: 'https://insar.gisat.cz/geoserver/',

	serverUrl: 'https://insar.gisat.cz/backend/',

	requestPageSize: 100
};
